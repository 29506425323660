import numberize from '../_helper/numberize.helper';
import axios from 'axios';
import {CONFIG} from '../config/config';

export default function (i18n){
    return {
        namespaced: true,
        state: {
            lw1: 0,
            lw2: 0,
            step: 0,
            requesting: false,
            hasError: false
        },
        mutations: {
            SET_LW1(state, payload) {
                state.lw1 = parseFloat(payload);
            },
            SET_LW2(state, payload) {
                state.lw2 = parseFloat(payload);
            },
            SET_STEP(state, payload) {
                state.step = payload ? parseInt(payload) : 0;
            },
            SET_REQUESTING(state, payload) {
                state.requesting = !!payload;
                state.hasError = false;
            },
            SET_ERROR(state, payload) {
                state.hasError = !!payload;
            }
        },
        getters: {
            result: state => {
                const AdditionLw_Calc = 10 * Math.log(Math.pow(10, state.lw1 / 10) + Math.pow(10, state.lw2 / 10)) / Math.log(10);
                return (Math.round(AdditionLw_Calc * 10) / 10).toFixed(1);
            }
        },
        actions: {
            async send(context, {email, accept}) {
                const json = {
                    type: "dba",
                    provider: "web",
                    title: i18n.t('dbaCalculatorResults'),
                    unit: "dB(A)",
                    value: numberize(context.getters.result, 1),
                    parts: [
                        {
                            title: i18n.t('emailDbaPartsTitle'),
                            children: {
                                "Lw 1": `${numberize(context.state.lw1, 1)} dB(A)`,
                                "Lw 2": `${numberize(context.state.lw2, 1)} dB(A)`
                            }
                        }
                    ],
                    footer: i18n.t('emailFooter'),
                    send: {
                        email: email,
                        accept: accept
                    },
                    pdf: [
                        {
                            type: "group",
                            title: i18n.t('dbaCalculatorResults'),
                            marginIncrement: 10,
                            child: [
                                {
                                    type: "square",
                                    title: i18n.t('emailDbaPartsTitle'),
                                    data: [
                                        {
                                            type: "text",
                                            name: "Lw 1",
                                            value: `${numberize(context.state.lw1, 1)} dB(A)`
                                        },
                                        {
                                            type: "text",
                                            name: "Lw 2",
                                            value: `${numberize(context.state.lw2, 1)} dB(A)`
                                        }
                                    ]
                                },
                                {
                                    type: "square",
                                    title: i18n.t('results'),
                                    data: [
                                        {
                                            type: "text",
                                            fontSize: 3,
                                            bold: true,
                                            name: i18n.t('value'),
                                            value: `${numberize(context.getters.result, 1)} dB(A)`
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                }
                context.commit('SET_REQUESTING', true);
                const response = await axios.post(CONFIG.api + '/pdf/mail/calculateur?cairox', json);
                context.commit('SET_REQUESTING', false);
                if (!response || response.code >= 300) {
                    context.commit('SET_ERROR', true);
                }
                context.commit('SET_STEP', 2);
                return response;
            }
        }
    }
}
