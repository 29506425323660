import Vue from 'vue';
import Vuex from 'vuex';

import i18n from "@/translations";
import dba from './store/dba.store'
import lwlp from './store/lwlp.store'
import ms from './store/ms.store'
import pa from './store/pa.store'
import {CONFIG} from "./config/config"
Vue.use(Vuex);


export const store = new Vuex.Store({
    state: {
        calculator: CONFIG.calculator,
        title: 'Cairox - ' + i18n.t(CONFIG.calculator+'Calculator')
    },
    modules: {dba: dba(i18n), lwlp: lwlp(i18n), ms: ms(i18n), pa: pa(i18n)},
    mutations: {},
    getters: {
        loading: () => false
    },
    actions: {}
})
